define("ember-awesome-macros/string/-utils", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.normalizeString = normalizeString;
  _exports.normalizeString2 = normalizeString2;

  function normalizeString(func) {
    return (0, _emberMacroHelpers.curriedComputed)(function (val) {
      if (!val) {
        return val;
      }

      return func(val);
    });
  }

  function normalizeString2(func) {
    var defaultValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {};
    return (0, _emberMacroHelpers.lazyCurriedComputed)(function (get, stringKey) {
      var stringVal = get(stringKey);

      if (stringVal === undefined) {
        return defaultValue();
      }

      var prop = stringVal[func];

      if (typeof prop === 'function') {
        for (var _len = arguments.length, keys = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
          keys[_key - 2] = arguments[_key];
        }

        return prop.apply(stringVal, keys.map(get));
      }

      return prop;
    });
  }
});