define('ember-mapbox-composer/components/labs-layers-tooltip', ['exports', 'ember-mapbox-composer/templates/components/labs-layers-tooltip'], function (exports, _labsLayersTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    style: Ember.computed('mousePosition', function () {
      var _get = this.get('mousePosition'),
          top = _get.y,
          left = _get.x;

      var offset = this.get('offset');

      return Ember.String.htmlSafe('\n      top: ' + (top + offset) + 'px; \n      left: ' + (left + offset) + 'px; \n      pointer-events: none;\n    ');
    }),

    layout: _labsLayersTooltip.default,

    /**
      Offset of tooltip div element in pixels.
      @argument offset
      @type Number
    */
    offset: 20,

    /**
      Top offset of tooltip div in pixels.
      @argument top
      @type Number
    */
    top: 0,

    /**
      Left offset of tooltip div in pixels.
      @argument left
      @type Number
    */
    left: 0,

    /**
      Native mousePosition object that is passed from labs-layers
      @type Object
      @private
    **/
    mousePosition: null,

    /**
      Geographic feature of the layer that is hovered when onLayerMouseMove is fired.
      @argument feature
      @type Object
      @private
    */
    feature: null,

    /**
      Layer that is hovered when onLayerMouseMove is fired.
      @argument layer
      @type Object
      @private
    */
    layer: null
  });
});