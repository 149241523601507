define('labs-ui/components/labs-ui/icons/fa-icon', ['exports', 'labs-ui/templates/components/labs-ui/icons/fa-icon'], function (exports, _faIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    tagName: 'span',
    classNames: ['legend-icon-layer'],
    layout: _faIcon.default,

    options: function options() {},


    spanStyle: Ember.computed('options.color', function () {
      return Ember.String.htmlSafe(this.options.color ? 'color: ' + this.options.color : '');
    })
  });
});