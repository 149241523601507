define("ember-awesome-macros/get-by", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.createClassComputed)([false, true], function (obj, key) {
    return Ember.computed.readOnly("".concat(obj, ".").concat(key));
  });

  _exports.default = _default;
});