define('ember-mapbox-composer/services/layer-groups', ['exports', 'ember-copy'], function (exports, _emberCopy) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var LayerGroupService = function (_EmberService) {
    _inherits(LayerGroupService, _EmberService);

    function LayerGroupService() {
      _classCallCheck(this, LayerGroupService);

      return _possibleConstructorReturn(this, (LayerGroupService.__proto__ || Object.getPrototypeOf(LayerGroupService)).apply(this, arguments));
    }

    _createClass(LayerGroupService, [{
      key: 'init',
      value: function init() {
        var _get2;

        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        (_get2 = _get(LayerGroupService.prototype.__proto__ || Object.getPrototypeOf(LayerGroupService.prototype), 'init', this)).call.apply(_get2, [this].concat(args));

        this.set('layerGroupRegistry', Ember.A([]));
        this.set('visibleLayerGroups', Ember.A([]));
      }
    }, {
      key: 'initializeObservers',
      value: function initializeObservers(layerGroups) {
        // set initial state from QPs, grab init state from models
        var defaultVisibleLayerGroups = (0, _emberCopy.copy)(layerGroups.filterBy('visible').mapBy('id').sort());
        var params = this.get('visibleLayerGroups');

        // set defaults through ember parachute
        // controller.setDefaultQueryParamValue('layerGroupService.visibleLayerGroups', defaultVisibleLayerGroups);

        // check if the provided params are the default
        var isDefaultState = defaultVisibleLayerGroups.every(function (layerGroup) {
          return params.any(function (param) {
            return (param.id || param) === layerGroup;
          });
        });

        // check if QP isn't default and there are other params
        if (!isDefaultState && params.length) {
          // set initial state from query params when not default
          layerGroups.forEach(function (layerGroup) {
            layerGroup.set('visible', params.any(function (param) {
              return (param.id || param) === layerGroup.id;
            }));

            if (layerGroup.get('layerVisibilityType') === 'singleton') {
              var _ref = params.find(function (param) {
                return (param.id || param) === layerGroup.id;
              }) || {},
                  selected = _ref.selected;

              if (selected) layerGroup.set('selected', selected);
            }
          });
        }

        this._modelsToParams();
        this._paramsToModels();

        this.addObserver('layerGroupRegistry.@each.selected', this, '_modelsToParams');
        this.addObserver('layerGroupRegistry.@each.visible', this, '_modelsToParams');
        this.addObserver('visibleLayerGroups.length', this, '_paramsToModels');
      }
    }, {
      key: '_modelsToParams',
      value: function _modelsToParams() {
        var layerGroups = this.get('layerGroupRegistry');

        // calculate new param state object
        var newParams = layerGroups.filter(function (layerGroup) {
          return layerGroup.get('visible');
        }).map(function (layerGroup) {
          if (layerGroup.get('layerVisibilityType') === 'singleton') {
            return { id: layerGroup.get('id'), selected: layerGroup.get('selected.id') };
          }

          return layerGroup.get('id');
        }).sort();

        // set the new param state object
        this.set('visibleLayerGroups', newParams);
      }
    }, {
      key: '_paramsToModels',
      value: function _paramsToModels() {
        var layerGroups = this.get('layerGroupRegistry');
        var params = this.get('visibleLayerGroups');

        if (Array.isArray(params) && layerGroups && params.length) {
          layerGroups.forEach(function (layerGroup) {
            var foundParam = params.find(function (param) {
              return (param.id || param) === layerGroup.id;
            });
            if (foundParam) {
              layerGroup.set('visible', true);

              if (foundParam.selected) {
                layerGroup.set('selected', foundParam.selected);
              }
            } else {
              layerGroup.set('visible', false);
            }
          });
        }
      }
    }]);

    return LayerGroupService;
  }(Ember.Service);

  exports.default = LayerGroupService;
});