define('labs-ui/components/labs-ui/layer-groups-container', ['exports', 'labs-ui/templates/components/labs-ui/layer-groups-container'], function (exports, _layerGroupsContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('layerGroupToggleItems', Ember.A([]));
    },


    layout: _layerGroupsContainer.default,

    classNames: ['layer-groups-container'],
    classNameBindings: ['open', 'numberMenuItems:has-active-layer-groups'],

    numberMenuItems: Ember.computed('layerGroupToggleItems.@each.active', function () {
      var items = this.get('layerGroupToggleItems');

      var activeStates = items.mapBy('active');

      return activeStates.reduce(function (acc, curr) {
        var mutatedAcc = acc;
        if (curr) {
          mutatedAcc += 1;
        }

        return mutatedAcc;
      }, 0);
    }),

    open: true,

    mapIsLoading: false,

    title: null,

    actions: {
      toggleLayerGroupsContainer: function toggleLayerGroupsContainer() {
        this.toggleProperty('open');
      },
      registerChild: function registerChild(componentContext) {
        var _this = this;

        Ember.run.next(function () {
          _this.get('layerGroupToggleItems').pushObject(componentContext);
        });
      },
      unregisterChild: function unregisterChild(componentContext) {
        var _this2 = this;

        Ember.run.next(function () {
          _this2.get('layerGroupToggleItems').removeObject(componentContext);
        });
      }
    }
  });
});