define('ember-mapbox-composer/adapters/layer-group', ['exports', 'ember-data', 'fetch'], function (exports, _emberData, _fetch) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;


  var DEFAULT_HOST = 'https://layers-api.planninglabs.nyc';

  var LayerGroupAdapter = function (_JSONAPIAdapter) {
    _inherits(LayerGroupAdapter, _JSONAPIAdapter);

    function LayerGroupAdapter() {
      var _ref;

      _classCallCheck(this, LayerGroupAdapter);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var _this = _possibleConstructorReturn(this, (_ref = LayerGroupAdapter.__proto__ || Object.getPrototypeOf(LayerGroupAdapter)).call.apply(_ref, [this].concat(args)));

      var _ref2 = Ember.getOwner(_this).resolveRegistration('config:environment')['ember-mapbox-composer'] || {},
          _ref2$host = _ref2.host,
          host = _ref2$host === undefined ? DEFAULT_HOST : _ref2$host;

      _this.set('host', host);
      _this.set('namespace', 'v1');
      return _this;
    }

    _createClass(LayerGroupAdapter, [{
      key: 'query',
      value: function query(store, type) {
        var _query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        var URL = this.buildURL(type.modelName);

        return (0, _fetch.default)('' + URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(_query)
        }).then(function (blob) {
          return blob.json();
        });
      }
    }]);

    return LayerGroupAdapter;
  }(JSONAPIAdapter);

  exports.default = LayerGroupAdapter;
});