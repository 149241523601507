define("ember-composable-helpers/helpers/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.groupBy = groupBy;
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function groupBy(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        byPath = _ref2[0],
        array = _ref2[1];

    var groups = {};
    array.forEach(function (item) {
      var groupName = Ember.get(item, byPath);
      var group = groups[groupName];

      if (!Array.isArray(group)) {
        group = [];
        groups[groupName] = group;
      }

      group.push(item);
    });
    return groups;
  }

  var _default = Ember.Helper.helper(groupBy);

  _exports.default = _default;
});