define("ember-awesome-macros/-utils", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reduceKeys = reduceKeys;
  _exports.reduceKeys2 = reduceKeys2;
  _exports.checkArgs = checkArgs;
  _exports.safelyCreateComputed = safelyCreateComputed;
  _exports.deprecate = deprecate;

  function reduceKeys(func) {
    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len = arguments.length, values = new Array(_len), _key = 0; _key < _len; _key++) {
        values[_key] = arguments[_key];
      }

      if (values.length === 0) {
        return 0;
      }

      return values.reduce(function (total, next, i) {
        if (Array.isArray(next)) {
          if (next.length === 0) {
            next = 0;
          } else {
            next = next.reduce(func);
          }
        }

        if (i === 0) {
          return next;
        }

        return func(total, next);
      }, null);
    });
  }

  function reduceKeys2(callback) {
    return (0, _emberMacroHelpers.lazyCurriedComputed)(function (get) {
      var last;

      for (var i = 0; i < (arguments.length <= 1 ? 0 : arguments.length - 1); i++) {
        last = get(i + 1 < 1 || arguments.length <= i + 1 ? undefined : arguments[i + 1]);

        if (callback(last)) {
          return last;
        }
      }

      return last;
    });
  }

  function checkArgs(callback) {
    return function () {
      for (var i = 0; i < arguments.length; i++) {
        if ((i < 0 || arguments.length <= i ? undefined : arguments[i]) === undefined) {
          return;
        }
      }

      return callback.apply(void 0, arguments);
    };
  }

  function safelyCreateComputed(funcStr) {
    return (0, _emberMacroHelpers.curriedComputed)(checkArgs(function (source) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      return source[funcStr].apply(source, args);
    }));
  }

  var projectName = 'ember-awesome-macros';
  var until = 'sometime before 1.0';

  function deprecate(newFunc, oldKey, newKey) {
    return Ember.deprecateFunc("".concat(oldKey, " is deprecated, please use ").concat(newKey), {
      id: "".concat(projectName, ".").concat(oldKey),
      until: until
    }, newFunc);
  }
});