define('ember-mapbox-composer/models/layer-group', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var hasMany = _emberData.default.hasMany,
      attr = _emberData.default.attr;
  exports.default = _model.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      // update registry for aggregate state service
      this.set('layerGroupService.layerGroupRegistry', this.get('layerGroupService.layerGroupRegistry').concat(this));
    },


    layers: hasMany('layer', { async: false }),

    /**
      Abstraction for the visibility state of related layers. Mutations will fire updates to child layers.
      Simple modifies a property of the MapboxGL `layout` style property. Does not add or remove layers.
       @property visible
      @type Boolean
    */
    visible: attr('boolean', { defaultValue: true }),

    /**
      This property describes the visibility state
      of the associated layers. Layer groups can have:
        - singleton layers (only one or none layers are visible)
          the top-most layer is on by default
        - multi (many may be visible or none)
        - binary (all are visible or none are visible)
       @property layerVisibilityType
      @type String('singleton', 'multi', 'binary')
    */
    layerVisibilityType: attr('string', { defaultValue: 'binary' }),

    titleTooltip: attr('string', { defaultValue: '' }),
    legendIcon: attr('string'),
    legendColor: attr('string'),
    legendConfig: attr(),

    /**
      A JSON object containing any number of keys and values to store metadata.
       @property meta
      @type Object
    */
    meta: attr(),

    legend: attr(),
    title: Ember.computed.alias('legend.label'),

    /**
      Convenience property for a list of internal MapboxGL layer IDs.
       @property layerIds
      @type Array
    */
    layerIds: Ember.computed.mapBy('layers', 'id'),

    // singleton only
    selected: Ember.computed('layers.@each.visibility', {
      get: function get() {
        return this.get('layers').findBy('visibility', true);
      },
      set: function set(key, id) {
        this.get('layers').setEach('visibility', false);
        this.get('layers').findBy('id', id).set('visibility', true);
      }
    }),

    /**
      This method finds a related layer and overwrites its paint object
       @method setPaintForLayer
      @param {String|Number} id ID of the layer-group's layer
      @param {Object} paint MapboxGL Style [paint](https://www.mapbox.com/mapbox-gl-js/style-spec/#layer-paint) object to override
    */
    setPaintForLayer: function setPaintForLayer() {
      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      this._mutateLayerProperty.apply(this, ['paint'].concat(_toConsumableArray(args)));
    },


    /**
      This method finds a related layer and overwrites its filter array
       @method setFilterForLayer
      @param {String|Number} id ID of the layer-group's layer
      @param {Object} filter MapboxGL Style [expressions array](https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions) to override
    */
    setFilterForLayer: function setFilterForLayer() {
      for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      this._mutateLayerProperty.apply(this, ['filter'].concat(_toConsumableArray(args)));
    },


    /**
      This method finds a related layer and overwrites its layout object
       @method setLayoutForLayer
      @param {String|Number} id ID of the layer-group's layer
      @param {Object} layout MapboxGL Style [layout](https://www.mapbox.com/mapbox-gl-js/style-spec/#layout-property) object to override
    */
    setLayoutForLayer: function setLayoutForLayer() {
      for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
        args[_key3] = arguments[_key3];
      }

      this._mutateLayerProperty.apply(this, ['layout'].concat(_toConsumableArray(args)));
    },


    /**
      This method hides all layers and shows only one
       @method showOneLayer
      @param {String|Number} id ID of the layer-group's layer
    */
    showOneLayer: function showOneLayer(id) {
      this.get('layers').forEach(function (layer) {
        if (layer.get('id') === id) {
          layer.set('layout', {} /* visible */);
        }

        layer.set('layout', {} /* not visible */);
      });
    },


    /**
      This method generically mutates a property on a related layer
       @method _mutateLayerProperty
      @private
      @param {String|Number} property of the layer-group's layer
      @param {String|Number} layerID ID of the layer-group's layer
      @param {Object} value Value of Layer to override
    */
    _mutateLayerProperty: function _mutateLayerProperty(property, layerID, value) {
      var foundLayer = this.get('layers').findBy('id', layerID);
      if (!foundLayer) throw Error('No related layer with this ID.');

      foundLayer.set(property, value);
    },


    /**
      Internal for determining first occurence of a layer
       @property _firstOccurringLayer
      @type Number
      @private
    */
    _firstOccurringLayer: null,

    layerGroupService: Ember.inject.service('layerGroups')
  });
});