define("labs-ui/templates/components/labs-ui/icon-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fmV1jPyG",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"fa-icon\",null,[[\"icon\",\"transform\",\"fixedWidth\"],[[23,[\"icon\"]],[23,[\"transform\"]],[23,[\"fixedWidth\"]]]]],false],[1,[27,\"ember-tooltip\",null,[[\"text\",\"side\",\"popperContainer\"],[[23,[\"tip\"]],[23,[\"side\"]],\"body\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/icon-tooltip.hbs"
    }
  });

  _exports.default = _default;
});