define("ember-awesome-macros/or", ["exports", "ember-awesome-macros/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.reduceKeys2)(function (value) {
    return value;
  });

  _exports.default = _default;
});