define("labs-ui/templates/components/labs-ui/layer-groups-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SL1ijP/1",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"layer-groups-container-title \",[27,\"unless\",[[23,[\"open\"]],\"closed\"],null],\" layer-groups-container--\",[27,\"dasherize\",[[23,[\"title\"]]],null]]]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"numberMenuItems\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"badge\"],[9],[1,[21,\"numberMenuItems\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"and\",[[23,[\"mapIsLoading\"]],[23,[\"open\"]],[23,[\"numberMenuItems\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"fa-icon\",[\"spinner\"],[[\"class\"],[\"fa-spin medium-gray\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[3,\"action\",[[22,0,[]],\"toggleLayerGroupsContainer\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"layer-groups-container-content\"],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"layer-group-toggle\"],[[27,\"component\",[\"labs-ui/layer-group-toggle\"],[[\"willDestroyHook\",\"didInit\"],[[27,\"action\",[[22,0,[]],\"unregisterChild\"],null],[27,\"action\",[[22,0,[]],\"registerChild\"],null]]]]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/layer-groups-container.hbs"
    }
  });

  _exports.default = _default;
});