define("@ember-decorators/component/index", ["exports", "@ember-decorators/utils/collapse-proto", "@ember-decorators/utils/decorator"], function (_exports, _collapseProto, _decorator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.layout = _exports.tagName = _exports.classNames = _exports.className = _exports.attribute = void 0;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  /**
    Decorator which indicates that the field or computed should be bound
    to an attribute value on the component. This replaces `attributeBindings`
    by directly allowing you to specify which properties should be bound.
  
    ```js
    export default class AttributeDemoComponent extends Component {
      @attribute role = 'button';
  
      // With provided attribute name
      @attribute('data-foo') foo = 'lol';
  
      @attribute
      @computed
      get id() {
        // return generated id
      }
    }
    ```
  
    @function
    @param {string} name? - The name of the attribute to bind the value to if it is truthy
  */
  var attribute = (0, _decorator.decoratorWithParams)(function (desc) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    (false && !(params.length <= 1) && Ember.assert("The @attribute decorator may take up to one parameter, the bound attribute name. Received: ".concat(params.length), params.length <= 1));
    (false && !(params.every(function (s) {
      return typeof s === 'string';
    })) && Ember.assert("The @attribute decorator may only receive strings as parameters. Received: ".concat(params), params.every(function (s) {
      return typeof s === 'string';
    })));

    desc.finisher = function (target) {
      var prototype = target.prototype;
      var key = desc.key,
          descriptor = desc.descriptor;
      (0, _collapseProto.default)(prototype);

      if (!prototype.hasOwnProperty('attributeBindings')) {
        var parentValue = prototype.attributeBindings;
        prototype.attributeBindings = Array.isArray(parentValue) ? parentValue.slice() : [];
      }

      var binding = params[0] ? "".concat(key, ":").concat(params[0]) : key;
      prototype.attributeBindings.push(binding);

      if (descriptor) {
        // Decorated fields are currently not configurable in Babel for some reason, so ensure
        // that the field becomes configurable (else it messes with things)
        descriptor.configurable = true;
      }

      return target;
    };

    return desc;
  });
  /**
    Decorator which indicates that the field or computed should be bound to
    the component class names. This replaces `classNameBindings` by directly
    allowing you to specify which properties should be bound.
  
    ```js
    export default class ClassNameDemoComponent extends Component {
      @className boundField = 'default-class';
  
      // With provided true/false class names
      @className('active', 'inactive') isActive = true;
  
      @className
      @computed
      get boundComputed() {
        // return generated class
      }
    }
    ```
  
    @function
    @param {string} truthyName? - The class to be applied if the value the field
                                  is truthy, defaults to the name of the field.
    @param {string} falsyName? - The class to be applied if the value of the field
                                 is falsy.
  */

  _exports.attribute = attribute;
  var className = (0, _decorator.decoratorWithParams)(function (desc) {
    var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    (false && !(params.length <= 2) && Ember.assert("The @className decorator may take up to two parameters, the truthy class and falsy class for the class binding. Received: ".concat(params.length), params.length <= 2));
    (false && !(params.every(function (s) {
      return typeof s === 'string';
    })) && Ember.assert("The @className decorator may only receive strings as parameters. Received: ".concat(params), params.every(function (s) {
      return typeof s === 'string';
    })));

    desc.finisher = function (target) {
      var prototype = target.prototype;
      var key = desc.key,
          descriptor = desc.descriptor;
      (0, _collapseProto.default)(prototype);

      if (!prototype.hasOwnProperty('classNameBindings')) {
        var parentValue = prototype.classNameBindings;
        prototype.classNameBindings = Array.isArray(parentValue) ? parentValue.slice() : [];
      }

      var binding = params.length > 0 ? "".concat(key, ":").concat(params.join(':')) : key;
      prototype.classNameBindings.push(binding);

      if (descriptor) {
        // Decorated fields are currently not configurable in Babel for some reason, so ensure
        // that the field becomes configurable (else it messes with things)
        descriptor.configurable = true;
      }

      return target;
    };

    return desc;
  });
  /**
    Class decorator which specifies the class names to be applied to a component.
    This replaces the `classNames` property on components in the traditional Ember
    object model.
  
    ```js
    @classNames('a-static-class', 'another-static-class')
    export default class ClassNamesDemoComponent extends Component {}
    ```
  
    @function
    @param {...string} classNames - The list of classes to be applied to the component
  */

  _exports.className = className;
  var classNames = (0, _decorator.decoratorWithRequiredParams)(function (desc, classNames) {
    (false && !(classNames.reduce(function (allStrings, name) {
      return allStrings && typeof name === 'string';
    }, true)) && Ember.assert("The @classNames decorator must be provided strings, received: ".concat(classNames), classNames.reduce(function (allStrings, name) {
      return allStrings && typeof name === 'string';
    }, true)));

    desc.finisher = function (target) {
      var prototype = target.prototype;
      (0, _collapseProto.default)(prototype);

      if ('classNames' in prototype) {
        var parentClasses = prototype.classNames;
        classNames.unshift.apply(classNames, _toConsumableArray(parentClasses));
      }

      prototype.classNames = classNames;
      return target;
    };

    return desc;
  }, 'classNames');
  /**
    Class decorator which specifies the tag name of the component. This replaces
    the `tagName` property on components in the traditional Ember object model.
  
    ```js
    @tagName('button')
    export default class TagNameDemoComponent extends Component {}
    ```
  
    @function
    @param {string} tagName - The HTML tag to be used for the component
  */

  _exports.classNames = classNames;
  var tagName = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    var _params = _slicedToArray(params, 1),
        tagName = _params[0];

    (false && !(params.length === 1) && Ember.assert("The @tagName decorator must be provided exactly one argument, received: ".concat(tagName), params.length === 1));
    (false && !(typeof tagName === 'string') && Ember.assert("The @tagName decorator must be provided a string, received: ".concat(tagName), typeof tagName === 'string'));

    desc.finisher = function (target) {
      target.prototype.tagName = tagName;
      return target;
    };

    return desc;
  }, 'tagName');
  /**
    Class decorator which specifies the layout for the component. This replaces
    the `layout` property on components in the traditional Ember object model.
  
    ```js
    import template from '../templates/components/x-foo';
  
    @layout(template)
    export default class TagNameDemoComponent extends Component {}
    ```
  
    ```js
    import hbs from 'htmlbars-inline-precompile';
  
    @layout(hbs`<h1>Hello {{ name }}</h1>`)
    export default class TagNameDemoComponent extends Component {
      constructor() {
        super(...arguments);
        this.set('name', 'Tomster');
      }
    }
    ```
  
    @function
    @param {TemplateFactory} template - The compiled template to be used for the component
  */

  _exports.tagName = tagName;
  var layout = (0, _decorator.decoratorWithRequiredParams)(function (desc, params) {
    var _params2 = _slicedToArray(params, 1),
        template = _params2[0];

    (false && !(params.length === 1) && Ember.assert("The @layout decorator must be provided exactly one argument, received: ".concat(params.length), params.length === 1));
    (false && !(typeof template !== 'string') && Ember.assert("The @layout decorator must be provided a template, received: ".concat(template, ". If you want to compile strings to templates, be sure to use 'htmlbars-inline-precompile'"), typeof template !== 'string'));
    (false && !(function () {
      return _typeof(template) === 'object' && typeof template.indexOf === 'undefined';
    }()) && Ember.assert("The @layout decorator must be provided a template, received: ".concat(template), function () {
      return _typeof(template) === 'object' && typeof template.indexOf === 'undefined';
    }()));

    desc.finisher = function (target) {
      target.prototype.layout = template;
      return target;
    };

    return desc;
  }, 'layout');
  _exports.layout = layout;
});