define("ember-awesome-macros/multiply", ["exports", "ember-awesome-macros/product"], function (_exports, _product) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _product.default;
    }
  });
});