define("labs-ui/templates/components/labs-ui/legend-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hZfDm2Hx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"grid-x\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cell auto\"],[9],[0,\"\\n    \"],[1,[27,\"labs-ui/legend-icon\",null,[[\"icon\"],[[23,[\"item\",\"icon\"]]]]],false],[0,\"\\n    \"],[1,[23,[\"item\",\"label\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cell shrink\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"item\",\"tooltip\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"labs-ui/icon-tooltip\",null,[[\"tip\",\"side\",\"fixedWidth\"],[[23,[\"item\",\"tooltip\"]],\"left\",true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/legend-item.hbs"
    }
  });

  _exports.default = _default;
});