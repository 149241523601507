define("ember-mapbox-composer/templates/components/labs-layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0aqNgpGs",
    "block": "{\"symbols\":[\"layerObject\",\"&default\"],\"statements\":[[1,[27,\"mapbox-gl-on\",[\"mousemove\",[27,\"action\",[[22,0,[]],\"handleLayerMouseMove\"],null]],[[\"eventSource\"],[[23,[\"map\"]]]]],false],[0,\"\\n\\n\"],[4,\"each\",[[23,[\"layers\"]]],[[\"key\"],[\"style\"]],{\"statements\":[[0,\"  \"],[1,[27,\"mapbox-gl-layer\",null,[[\"map\",\"layer\",\"before\"],[[23,[\"map\"]],[22,1,[\"mapboxGlStyle\"]],[22,1,[\"before\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[27,\"or\",[[22,1,[\"highlightable\"]],[22,1,[\"tooltipable\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[27,\"mapbox-gl-on\",[\"mouseleave\",[22,1,[\"mapboxGlStyle\",\"id\"]],[27,\"action\",[[22,0,[]],\"handleLayerMouseLeave\"],null]],[[\"eventSource\"],[[23,[\"map\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[22,1,[\"clickable\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"mapbox-gl-on\",[\"click\",[22,1,[\"mapboxGlStyle\",\"id\"]],[27,\"action\",[[22,0,[]],\"handleLayerMouseClick\"],null]],[[\"eventSource\"],[[23,[\"map\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[14,2,[[27,\"hash\",null,[[\"tooltip\"],[[27,\"if\",[[27,\"and\",[[23,[\"mousePosition\"]],[23,[\"hoveredFeature\"]],[23,[\"hoveredLayer\",\"tooltipable\"]]],null],[27,\"component\",[[23,[\"toolTipComponent\"]]],[[\"feature\",\"layer\",\"mousePosition\",\"top\",\"left\"],[[23,[\"hoveredFeature\"]],[23,[\"hoveredLayer\"]],[23,[\"mousePosition\"]],[23,[\"mousePosition\",\"y\"]],[23,[\"mousePosition\",\"x\"]]]]]],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-composer/templates/components/labs-layers.hbs"
    }
  });

  _exports.default = _default;
});