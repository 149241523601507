define("@ember-decorators/data/index", ["exports", "ember-data", "@ember-decorators/utils/computed"], function (_exports, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.belongsTo = _exports.hasMany = _exports.attr = void 0;

  var _this = void 0;

  /**
    Decorator that turns the property into an Ember Data attribute
  
    ```js
    export default class User extends Model {
      @attr firstName;
  
      @attr('string') lastName;
  
      @attr('number', { defaultValue: 0 })
      age;
    }
    ```
  
    @function
    @param {string} type? - Type of the attribute
    @param {object} options? - Options for the attribute
  */
  var attr;
  /**
    Decorator that turns the property into an Ember Data `hasMany` relationship
  
    ```js
    export default class User extends Model {
      @hasMany posts;
  
      @hasMany('user') friends;
  
      @hasMany('user', { async: false })
      followers;
    }
    ```
  
    @function
    @param {string} type? - Type of relationship
    @param {object} options? - Options for the relationship
  */

  _exports.attr = attr;
  var hasMany;
  /**
    Decorator that turns the property into an Ember Data `belongsTo` relationship
  
    ```javascript
    export default class Post extends Model {
      @belongsTo user;
  
      @belongsTo('user') editor
  
      @belongsTo('post', { async: false })
      parentPost;
    }
    ```
    @function
    @param {string} type? - Type of the relationship
    @param {object} options? - Type of the relationship
  */

  _exports.hasMany = hasMany;
  var belongsTo;
  _exports.belongsTo = belongsTo;

  if (false) {
    _exports.attr = attr = (0, _computed.computedDecoratorWithParams)(_emberData.default.attr, 'attr', 'let { attr } = DS');
    _exports.hasMany = hasMany = (0, _computed.computedDecoratorWithParams)(_emberData.default.hasMany, 'hasMany', 'let { hasMany } = DS');
    _exports.belongsTo = belongsTo = (0, _computed.computedDecoratorWithParams)(_emberData.default.belongsTo, 'belongsTo', 'let { belongsTo } = DS');
  } else {
    _exports.attr = attr = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      return _emberData.default.attr.apply(_this, params);
    }, 'attr', 'let { attr } = DS');
    _exports.hasMany = hasMany = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      return _emberData.default.hasMany.apply(_this, params);
    }, 'hasMany', 'let { hasMany } = DS');
    _exports.belongsTo = belongsTo = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      return _emberData.default.belongsTo.apply(_this, params);
    }, 'belongsTo', 'let { belongsTo } = DS');
  }
});