define('labs-ui/components/labs-ui/site-header-title', ['exports', 'labs-ui/templates/components/labs-ui/site-header-title'], function (exports, _siteHeaderTitle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    layout: _siteHeaderTitle.default
  });
});