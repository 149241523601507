define("labs-ui/templates/components/labs-ui/icons/line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pWvn0usW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"d\",\"M0 5 l215 0\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/icons/line.hbs"
    }
  });

  _exports.default = _default;
});