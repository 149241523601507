define("ember-awesome-macros/to-string", ["exports", "ember-awesome-macros/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.safelyCreateComputed)('toString');

  _exports.default = _default;
});