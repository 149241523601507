define("ember-awesome-macros/array/sort", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _default(array, sortDefinition) {
    var computedCallback;

    if (sortDefinition === undefined) {
      computedCallback = function computedCallback(array) {
        return array.slice().sort();
      };
    } else {
      computedCallback = function computedCallback(array, sortDefinition) {
        var sortCallback;

        if (typeof sortDefinition === 'function') {
          sortCallback = sortDefinition.bind(this);
        } else {
          sortCallback = function sortCallback(a, b) {
            var result = 0; // https://kangax.github.io/compat-table/es6/#test-generators
            // for (let key of sortDefinition) {

            for (var i = 0; i < sortDefinition.length; i++) {
              var key = sortDefinition[i];

              var _key$split = key.split(':'),
                  _key$split2 = _slicedToArray(_key$split, 2),
                  prop = _key$split2[0],
                  direction = _key$split2[1];

              result = Ember.compare(Ember.get(a, prop), Ember.get(b, prop));

              if (result !== 0) {
                if (direction === 'desc') {
                  result *= -1;
                }

                break;
              }
            }

            return result;
          };
        }

        return array.slice().sort(sortCallback);
      };
    }

    return (0, _utils.normalizeArray)({}, computedCallback)(array, sortDefinition);
  }
});