define("ember-awesome-macros/conditional", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.lazyCurriedComputed)(function (get, condition, expr1, expr2) {
    return get(condition) ? get(expr1) : get(expr2);
  });

  _exports.default = _default;
});