define("ember-awesome-macros/promise/hash", ["exports", "ember-macro-helpers", "ember-awesome-macros/hash"], function (_exports, _emberMacroHelpers, _hash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _default() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var _deconstructArgs = (0, _hash.deconstructArgs)(args),
        hashKeys = _deconstructArgs.hashKeys,
        hashValues = _deconstructArgs.hashValues;

    return (0, _emberMacroHelpers.curriedComputed)(function () {
      for (var _len2 = arguments.length, newValues = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        newValues[_key2] = arguments[_key2];
      }

      var newHash = (0, _hash.reduceValues)(hashKeys, newValues);
      return Ember.RSVP.hash(newHash);
    }).apply(void 0, _toConsumableArray(hashValues));
  }
});