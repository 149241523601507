define("ember-awesome-macros/instance-of", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.curriedComputed)(function (object, constructor) {
    if (constructor === undefined) {
      return undefined;
    }

    return object instanceof constructor;
  });

  _exports.default = _default;
});