define('cartobox-promises-utility/utils/normalize-carto-vectors', ['exports', 'cartobox-promises-utility/utils/carto'], function (exports, _carto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = normalizeCartoVectors;
  var getVectorTileTemplate = _carto.default.getVectorTileTemplate;
  function normalizeCartoVectors() {
    var pseudoMapboxGlSources = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];

    // coerce to an array
    var iterable = Ember.isArray(pseudoMapboxGlSources) ? pseudoMapboxGlSources : [pseudoMapboxGlSources];

    // normalize into mapbox-gl source spec
    return Ember.RSVP.Promise.all(iterable.map(function (source) {
      var _EmberGetProperties = Ember.getProperties(source, 'id', 'type', 'minzoom', 'source-layers'),
          id = _EmberGetProperties.id,
          type = _EmberGetProperties.type,
          _EmberGetProperties$m = _EmberGetProperties.minzoom,
          minzoom = _EmberGetProperties$m === undefined ? 0 : _EmberGetProperties$m,
          sourceLayers = _EmberGetProperties['source-layers'];

      if (type !== 'cartovector') {
        return new Ember.RSVP.Promise(function (resolve) {
          var _EmberGetProperties2 = Ember.getProperties(source, 'tiles', 'tileSize'),
              tiles = _EmberGetProperties2.tiles,
              tileSize = _EmberGetProperties2.tileSize;

          resolve({
            id: id,
            type: type,
            tiles: tiles,
            tileSize: tileSize
          });
        });
      }

      return getVectorTileTemplate(sourceLayers).then(function (template) {
        return {
          id: id,
          type: 'vector',
          tiles: [template],
          minzoom: minzoom
        };
      });
    }));
  }
});