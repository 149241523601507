define('labs-ui/components/labs-ui/site-header', ['exports', 'labs-ui/templates/components/labs-ui/site-header'], function (exports, _siteHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['site-header'],

    layout: _siteHeader.default,

    ariaRole: 'banner',

    closed: true,

    responsiveNav: false,

    responsiveSize: 'large',

    betaNotice: true
  });
});