define('cartobox-promises-utility/utils/carto', ['exports', 'fetch', 'cartobox-promises-utility/configuration'], function (exports, _fetch, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.buildSqlUrl = undefined;


  var cartoUsername = (0, _configuration.default)()['carto-username'] || 'planninglabs';
  var cartoDomain = cartoUsername + '.carto.com';

  var buildTemplate = function buildTemplate(cartoResponse, type) {
    var layergroupid = cartoResponse.layergroupid,
        cdn_url = cartoResponse.cdn_url;
    var subdomains = cdn_url.templates.https.subdomains;


    // choose a subdomain at random
    var subdomain = subdomains[Math.floor(Math.random() * subdomains.length)];

    return cdn_url.templates.https.url.replace('{s}', subdomain) + '/' + cartoUsername + '/api/v1/map/' + layergroupid + '/{z}/{x}/{y}.' + type;
  };

  var buildSqlUrl = function buildSqlUrl(cleanedQuery) {
    var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
    // eslint-disable-line
    return 'https://' + cartoDomain + '/api/v2/sql?q=' + cleanedQuery + '&format=' + type;
  };

  var carto = {
    SQL: function SQL(query) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';

      var cleanedQuery = query.replace('\n', '');
      var url = buildSqlUrl(cleanedQuery, type);

      return (0, _fetch.default)(url).then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Not found');
      }).then(function (d) {
        // eslint-disable-line
        return type === 'json' ? d.rows : d;
      });
    },
    getVectorTileTemplate: function getVectorTileTemplate(sourceLayers) {
      var CartoCSS = '#layer { polygon-fill: #FFF; }';
      var layers = sourceLayers.map(function (sourceLayer) {
        var id = sourceLayer.id,
            sql = sourceLayer.sql;

        return {
          id: id,
          type: 'mapnik',
          options: {
            cartocss_version: '2.3.0',
            cartocss: CartoCSS,
            sql: sql
          }
        };
      });

      var params = {
        version: '1.3.0',
        layers: layers
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        (0, _fetch.default)('https://' + cartoDomain + '/api/v1/map', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        }).catch(function (err) {
          return reject(err);
        }).then(function (response) {
          return response.json();
        }).then(function (json) {
          resolve(buildTemplate(json, 'mvt'));
        });
      });
    }
  };

  exports.buildSqlUrl = buildSqlUrl;
  exports.default = carto;
});