define("cartobox-promises-utility/configuration", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setConfiguration = setConfiguration;
  exports.default = getConfiguration;
  var configuration = {};

  function setConfiguration(settings) {
    configuration = settings;
  }

  function getConfiguration() {
    return configuration;
  }
});