define('labs-ember-search/components/labs-bbl-lookup', ['exports', 'labs-ember-search/templates/components/labs-bbl-lookup', 'cartobox-promises-utility/utils/carto'], function (exports, _labsBblLookup, _carto) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('boroOptions', [{ name: 'Manhattan (1)', code: '1' }, { name: 'Bronx (2)', code: '2' }, { name: 'Brooklyn (3)', code: '3' }, { name: 'Queens (4)', code: '4' }, { name: 'Staten Island (5)', code: '5' }]);
    },


    classNames: ['bbl-lookup hide-for-print'],
    layout: _labsBblLookup.default,

    validBlock: false,
    validLot: false,

    boro: '',

    block: '',

    lot: '',

    submitText: 'Go to Lot',

    errorMessage: '',

    closed: true,

    flyTo: null,

    actions: {
      validate: function validate() {
        var boro = this.get('boro');
        var block = this.get('block');
        var lot = this.get('lot');

        var validBoro = boro !== '';
        var validBlock = block !== '' && parseInt(block, 10) < 100000 && parseInt(block, 10) > 0;
        var validLot = lot !== '' && parseInt(lot, 10) < 10000 && parseInt(lot, 10) > 0;

        this.set('validBlock', validBoro && validBlock);
        this.set('validLot', validBoro && validBlock && validLot);

        var submitText = validBlock && !validLot ? 'Go to Block' : 'Go to Lot';
        this.set('submitText', submitText);
      },
      handleSubmit: function handleSubmit() {
        var _this = this;

        var _getProperties = this.getProperties('boro', 'block', 'lot'),
            code = _getProperties.boro.code,
            block = _getProperties.block,
            lot = _getProperties.lot;

        var validBlock = this.get('validBlock');
        var validLot = this.get('validLot');

        if (validBlock && !validLot) {
          var SQL = 'SELECT the_geom FROM dof_dtm_block_centroids WHERE block= ' + parseInt(block, 10) + ' AND boro = \'' + code + '\'';
          _carto.default.SQL(SQL, 'geojson').then(function (response) {
            if (response.features[0]) {
              _this.set('errorMessage', '');
              _this.setProperties({
                closed: true
              });
              _this.onSuccess(response.features[0].geometry.coordinates, 16);
            } else {
              _this.set('errorMessage', 'The Block does not exist.');
            }
          });
        } else {
          var _SQL = 'SELECT st_centroid(the_geom) as the_geom, bbl FROM dcp_mappluto WHERE block= ' + parseInt(block, 10) + ' AND lot = ' + parseInt(lot, 10) + ' AND borocode = ' + code;
          _carto.default.SQL(_SQL, 'geojson').then(function (response) {
            if (response.features[0]) {
              _this.set('errorMessage', '');
              _this.setProperties({
                closed: true
              });
              var bblFeature = response.features[0];

              _this.onSuccess(bblFeature.geometry.coordinates, 18, bblFeature.properties.bbl);
            } else {
              _this.set('errorMessage', 'The Lot does not exist.');
            }
          });
        }
      },
      setBorocode: function setBorocode(option) {
        this.set('boro', option);
        this.send('validate');
      }
    }
  });
});