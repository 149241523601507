define('labs-ui/components/labs-ui/icons/rectangle', ['exports', 'labs-ui/templates/components/labs-ui/icons/rectangle'], function (exports, _rectangle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },


    tagName: 'svg',
    classNames: ['legend-icon-layer', 'rectangle'],

    layout: _rectangle.default,

    svgOptions: Ember.computed('options', function () {
      var defaultOptions = {
        stroke: 'SteelBlue',
        fill: 'rgba(70, 130, 180, 0.5)',
        'stroke-linejoin': 'round'
      };
      var options = this.get('options'); // options should be an object

      return Object.assign(defaultOptions, options);
    }),

    height: 10,
    width: 17,
    viewBox: '0 0 17 10',
    preserveAspectRatio: 'xMinYMid',

    didInsertElement: function didInsertElement() {
      var svgPath = this.element.querySelector('path');
      var svgOptions = this.get('svgOptions');
      Object.entries(svgOptions).forEach(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            attr = _ref2[0],
            value = _ref2[1];

        svgPath.setAttribute(attr, value);
      });
    }
  });
});