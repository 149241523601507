define("ember-cli-notifications/templates/components/notification-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HFAD+GQk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[[27,\"unbound\",[[23,[\"__styles__\",\"c-notification__icon\"]]],null]]]],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[28,[[21,\"notificationIcon\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[[27,\"unbound\",[[23,[\"__styles__\",\"c-notification__content\"]]],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"notification\",\"htmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"notification\",\"message\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,[\"notification\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"div\"],[11,\"title\",\"Dismiss this notification\"],[12,\"class\",[28,[[27,\"unbound\",[[23,[\"__styles__\",\"c-notification__close\"]]],null]]]],[9],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[28,[[21,\"closeIcon\"]]]],[9],[10],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"removeNotification\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"style\",[21,\"notificationClearDuration\"]],[12,\"class\",[28,[[27,\"unbound\",[[23,[\"__styles__\",\"c-notification__countdown\"]]],null]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-notifications/templates/components/notification-message.hbs"
    }
  });

  _exports.default = _default;
});