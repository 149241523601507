define("ember-awesome-macros/math/pow", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.curriedComputed)(Math.pow);

  _exports.default = _default;
});