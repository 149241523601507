define("labs-ui/templates/components/labs-ui/icons/rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XOQ9oics",
    "block": "{\"symbols\":[],\"statements\":[[7,\"path\"],[11,\"d\",\"M3 2L14 2L14 8L3 8Z\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/icons/rectangle.hbs"
    }
  });

  _exports.default = _default;
});