define("ember-cli-foundation-6-sass/templates/components/zf-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lZ06oJ5f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[4,\"if\",[[23,[\"flash\"]]],null,{\"statements\":[[0,\"    \"],[14,1,[[22,0,[]],[23,[\"flash\"]]]],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"flash\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"flash\",\"message\"]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"content\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"content\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-foundation-6-sass/templates/components/zf-callout.hbs"
    }
  });

  _exports.default = _default;
});