define("ember-mapbox-composer/templates/components/labs-layers-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ORfevkts",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"map-tooltip\"],[12,\"style\",[21,\"style\"]],[9],[0,\"\\n  \"],[14,1,[[27,\"hash\",null,[[\"feature\",\"layer\"],[[23,[\"feature\"]],[23,[\"layer\"]]]]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-composer/templates/components/labs-layers-tooltip.hbs"
    }
  });

  _exports.default = _default;
});