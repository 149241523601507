define('ember-mapbox-gl/components/mapbox-gl-source', ['exports', 'ember-mapbox-gl/templates/components/mapbox-gl-source'], function (exports, _mapboxGlSource) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mapboxGlSource.default,
    tagName: '',

    map: null,

    /**
     * @param string
     * @description The source options to add, conforming to the Mapbox Source spec.
     * {@link https://www.mapbox.com/mapbox-gl-js/style-spec/#sources Mapbox}
    */
    options: null,

    /**
     * @param object
     * @description The ID of the source to add. Must not conflict with existing sources.
     * {@link https://www.mapbox.com/mapbox-gl-js/api/#map#addsource Mapbox}
    */
    sourceId: Ember.computed(function () {
      return Ember.guidFor(this);
    }),

    init: function init() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'sourceId', 'options'),
          sourceId = _EmberGetProperties.sourceId,
          options = _EmberGetProperties.options;

      this.map.addSource(sourceId, options);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      var _EmberGetProperties2 = Ember.getProperties(this, 'sourceId', 'options'),
          sourceId = _EmberGetProperties2.sourceId,
          options = _EmberGetProperties2.options;

      if (options) {
        if (options.data) {
          this.map.getSource(sourceId).setData(options.data);
        } else if (options.coordinates) {
          this.map.getSource(sourceId).setCoordinates(options.coordinates);
        }
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var sourceId = Ember.get(this, 'sourceId');

      // wait for any layers to be removed before removing the source
      Ember.run.scheduleOnce('afterRender', this.map, this.map.removeSource, sourceId);
    }
  });
});