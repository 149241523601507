define('ember-mapbox-composer/models/layer', ['exports', 'ember-data/model', 'ember-copy', 'ember-data'], function (exports, _model, _emberCopy, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _model.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      // enforce presence of blank object for mapboxGL validation
      if (!this.get('style.layout')) this.set('style.layout', {});

      // determine which is the first occurring layer
      // for testing, should check that a related layer group exists
      if (this.get('layerGroup') && !this.get('layerGroup._firstOccurringLayer')) {
        this.set('layerGroup._firstOccurringLayer', this.get('id'));
        this.set('position', 1);
      }

      this.delegateVisibility();
      this.addObserver('layerGroup.visible', this, 'delegateVisibility');
    },
    delegateVisibility: function delegateVisibility() {
      var _this = this;

      var visible = this.get('layerGroup.visible');

      if (this.get('layerVisibilityType') === 'singleton') {
        if (this.get('position') === 1 && this.get('layerGroup.visible')) {
          Ember.run.next(function () {
            return _this.set('visibility', true);
          });
        } else {
          Ember.run.next(function () {
            return _this.set('visibility', false);
          });
        }
      } else {
        Ember.run.next(function () {
          return _this.set('visibility', visible);
        });
      }
    },


    layerGroup: belongsTo('layer-group', { async: false }),

    position: attr('number', { defaultValue: -1 }),
    before: attr('string', { defaultValue: 'boundary_country' }),
    displayName: attr('string'),
    style: attr('hash', { defaultValue: function defaultValue() {
        return {};
      } }),

    /**
      Determines whether to fire mouseover events for the layer.
      @property highlightable
      @type Boolean
    */
    highlightable: attr('boolean', { defaultValue: false }),

    /**
      Determines whether to fire click events for the layer.
      @property clickable
      @type Boolean
    */
    clickable: attr('boolean', { defaultValue: false }),

    /**
      Determines whether to render positioned tooltip components for the layer.
      @property tooltipable
      @type Boolean
    */
    tooltipable: attr('boolean', { defaultValue: false }),

    /**
      Optional template for tooltips. Does not handle any rendering.
      @property tooltipTemplate
      @type String
    */
    tooltipTemplate: attr('string', { defaultValue: '' }),

    paint: Ember.computed.alias('style.paint'),
    layout: Ember.computed.alias('style.layout'),
    layerVisibilityType: Ember.computed.alias('layerGroup.layerVisibilityType'),

    /**
      Computed alias that returns a newly built mapbox layer object. Necessary to maintain state bindings.
      @property mapboxGlStyle
      @type Object
      @private
    */
    mapboxGlStyle: Ember.computed('style.{paint,layout,filter}', function () {
      return this.get('style');
    }),

    /**
      Getter and setter for filter. Array structure should follow Mapbox's [Expression](https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions) syntax.
      @property filter
      @type Array
    */
    filter: Ember.computed('style.filter', {
      get: function get() {
        return this.get('style.filter');
      },
      set: function set(key, filter) {
        this.set('style', Ember.assign({}, this.get('style'), { filter: filter }));
      }
    }),

    /**
      Getter and setter for visibility. Mutates a Mapbox property that actually determines visibility. Depends on parent visibility.
       @property visibility
      @type Boolean
    */
    visibility: Ember.computed('layout.visibility', {
      get: function get() {
        return this.get('layout.visibility') === 'visible';
      },
      set: function set(key, value) {
        var parentVisibilityState = value && this.get('layerGroup.visible');
        var visibility = parentVisibilityState ? 'visible' : 'none';
        var layout = (0, _emberCopy.copy)(this.get('layout'));

        if (layout) {
          Ember.set(layout, 'visibility', visibility);
          this.set('layout', layout);
        }

        return visibility === 'visible';
      }
    })
  });
});