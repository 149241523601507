define("ember-awesome-macros/array/reduce", ["exports", "ember-awesome-macros/array/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.normalizeArray)({}, function (array, callback, initialValue) {
    if (typeof initialValue === 'function') {
      initialValue = initialValue();
    }

    return array.reduce(callback, initialValue);
  });

  _exports.default = _default;
});