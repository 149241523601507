define('labs-ui/components/labs-ui/icon-tooltip', ['exports', 'labs-ui/templates/components/labs-ui/icon-tooltip'], function (exports, _iconTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: 'icon-tooltip',

    layout: _iconTooltip.default,
    tip: '',
    side: 'top',
    icon: 'info-circle',
    transform: '',
    fixedWidth: false
  });
});