define('ember-cli-foundation-6-sass/initializers/zf-widget', ['exports', 'foundation-sites'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
    if (Ember.$ && Ember.typeOf(Ember.$().foundation) === 'function') {
      Ember.$().foundation();
    }
  }

  exports.default = {
    name: 'zf-widget',
    initialize: initialize
  };
});