define('labs-ui/components/labs-ui/legend-icon', ['exports', 'labs-ui/templates/components/labs-ui/legend-icon'], function (exports, _legendIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },


    icon: null,

    classNames: ['legend-icon'],
    layout: _legendIcon.default,

    iconType: Ember.computed(function () {
      var type = this.get('icon.type');
      return type === 'fa-icon' ? 'fa-layers' : type;
    })
  });
});