define('ember-mapbox-composer/components/labs-map', ['exports', 'ember-mapbox-gl/components/mapbox-gl', 'ember-mapbox-composer/templates/components/labs-map'], function (exports, _mapboxGl, _labsMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var highlightedCircleFeatureLayer = {
    id: 'highlighted-feature-circle',
    source: 'hovered-feature',
    type: 'circle',
    paint: {
      'circle-color': 'rgba(255, 255, 255, 0.65)',
      'circle-opacity': 1,
      'circle-radius': {
        stops: [[16, 3], [17, 6]]
      },
      'circle-stroke-opacity': 0.8,
      'circle-stroke-color': '#ffff00',
      'circle-stroke-width': 2.5,
      'circle-pitch-scale': 'map'
    }
  };

  var highlightedLineFeatureLayer = {
    id: 'highlighted-feature-line',
    source: 'hovered-feature',
    type: 'line',
    paint: {
      'line-color': '#ffff00',
      'line-opacity': 0.3,
      'line-width': {
        stops: [[8, 4], [11, 7]]
      }
    }
  };

  /**
    Extends `mapbox-gl` component to yield `labs-layers` contextual component. Allows passage of layer-groups.
  
    ```js
    // routes/application.js
    import Route from '@ember/routing/route';
  
    export default class ApplicationRoute extends Route {
      async model() {
        return this.store.query('layer-group');
      }
    }
  
    ```
    ```handlebars
    {{!-- routes/application.hbs --}}
    {{labs-map layerGroups=model}}
    ```
  
    @class LabsMapComponent
    @public
  */
  exports.default = _mapboxGl.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      // if layerGroups are passed to the map, use the style from that
      if (this.get('layerGroups')) {
        var _ref = this.get('layerGroups') || {},
            mapboxStyle = _ref.meta.mapboxStyle;

        if (mapboxStyle) Ember.assign(Ember.get(this, 'initOptions') || {}, { style: mapboxStyle });
      }
    },


    layout: _labsMap.default,

    hoveredFeatureSource: Ember.computed('hoveredFeature', function () {
      var feature = this.get('hoveredFeature');

      return {
        type: 'geojson',
        data: feature
      };
    }),

    hoveredFeature: null,
    highlightedCircleFeatureLayer: highlightedCircleFeatureLayer,
    highlightedLineFeatureLayer: highlightedLineFeatureLayer,

    /**
      Collection of layer-group models (see: [DS.RecordArray](https://emberjs.com/api/ember-data/release/classes/DS.RecordArray)).
      Allows optional `meta` object with a `mapboxStyle` property which is passed to the mapbox-gl instance.
      @argument layerGroups
      @type DS.RecordArray
    */
    layerGroups: null,

    _onLoad: function _onLoad(map) {
      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      this._super.apply(this, [map].concat(_toConsumableArray(args)));

      // add source for highlighted-feature
      if (!this.get('isDestroyed')) map.addSource('hovered-feature', this.get('hoveredFeatureSource'));
    }
  });
});