define('ember-cli-foundation-6-sass/components/zf-callout', ['exports', 'ember-cli-foundation-6-sass/templates/components/zf-callout'], function (exports, _zfCallout) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    layout: _zfCallout.default,
    type: '',
    content: null,
    classNameBindings: ['alertType', 'active', 'exiting', 'flashType'],
    classNames: ['callout'],

    // handle bindings for ember-cli-flash integration
    flashType: computed('flash.type', function () {
      return this.get('flash.type');
    }),

    // handle bindings for regular integration
    alertType: computed('type', function () {
      return this.get('type');
    })
  });
});