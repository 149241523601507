define("ember-awesome-macros/string/camelize", ["exports", "ember-awesome-macros/string/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.normalizeString)(Ember.String.camelize);

  _exports.default = _default;
});