define('ember-mapbox-gl/components/mapbox-gl-on', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MapboxGlOnComponent = Ember.Component.extend({
    tagName: '',

    eventSource: null,
    event: null,
    layerId: null,
    action: null,

    _prevEvent: null,
    _prevLayerId: null,

    _event: Ember.computed('event', function () {
      var event = Ember.get(this, 'event');
      (false && !(typeof event === 'string') && Ember.assert('mapbox-gl-event requires event to be a string, was ' + event, typeof event === 'string'));


      return event.toLowerCase();
    }),

    _layerId: Ember.computed('layerId', '_action', function () {
      var _EmberGetProperties = Ember.getProperties(this, 'layerId', '_action'),
          layerId = _EmberGetProperties.layerId,
          _action = _EmberGetProperties._action;

      if (layerId === _action) {
        return null;
      }

      return layerId;
    }),

    _action: Ember.computed('layerId', 'action', function () {
      var _EmberGetProperties2 = Ember.getProperties(this, 'layerId', 'action'),
          layerId = _EmberGetProperties2.layerId,
          action = _EmberGetProperties2.action;

      return action || layerId;
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._boundOnEvent = Ember.run.bind(this, this._onEvent);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _EmberGetProperties3 = Ember.getProperties(this, 'eventSource', '_layerId', '_event', '_prevEvent', '_prevLayerId', '_action'),
          eventSource = _EmberGetProperties3.eventSource,
          _layerId = _EmberGetProperties3._layerId,
          _event = _EmberGetProperties3._event,
          _prevEvent = _EmberGetProperties3._prevEvent,
          _prevLayerId = _EmberGetProperties3._prevLayerId,
          _action = _EmberGetProperties3._action;

      (false && !(Ember.isPresent(eventSource)) && Ember.assert('mapbox-gl-event requires an eventSource', Ember.isPresent(eventSource)));
      (false && !(Ember.isPresent(_action)) && Ember.assert('mapbox-gl-event requires an action', Ember.isPresent(_action)));


      if (_event !== _prevEvent || _layerId !== _prevLayerId) {
        if (_prevEvent) {
          if (_prevLayerId) {
            eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
          } else {
            eventSource.off(_prevEvent, this._boundOnEvent);
          }
        }

        this._prevEvent = _event;
        this._prevLayerId = _layerId;

        if (_layerId) {
          eventSource.on(_event, _layerId, this._boundOnEvent);
        } else {
          eventSource.on(_event, this._boundOnEvent);
        }
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      var _EmberGetProperties4 = Ember.getProperties(this, 'eventSource', '_prevEvent', '_prevLayerId'),
          eventSource = _EmberGetProperties4.eventSource,
          _prevEvent = _EmberGetProperties4._prevEvent,
          _prevLayerId = _EmberGetProperties4._prevLayerId;

      if (eventSource && _prevEvent) {
        if (_prevLayerId) {
          eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
        } else {
          eventSource.off(_prevEvent, this._boundOnEvent);
        }
      }
    },
    _onEvent: function _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      Ember.get(this, '_action').apply(undefined, arguments);
    }
  });

  MapboxGlOnComponent.reopenClass({
    positionalParams: ['event', 'layerId', 'action']
  });

  exports.default = MapboxGlOnComponent;
});