define("ember-awesome-macros/subtract", ["exports", "ember-awesome-macros/difference"], function (_exports, _difference) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _difference.default;
    }
  });
});