define('ember-mapbox-gl/components/mapbox-gl-popup', ['exports', 'ember-mapbox-gl/templates/components/mapbox-gl-popup', 'mapbox-gl'], function (exports, _mapboxGlPopup, _mapboxGl) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _mapboxGlPopup.default,
    tagName: '',

    map: null,
    marker: null,
    lngLat: null,
    initOptions: null,

    onClose: function onClose() {},
    init: function init() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'initOptions', 'marker'),
          initOptions = _EmberGetProperties.initOptions,
          marker = _EmberGetProperties.marker;

      this.domContent = document.createElement('div');
      this._onClose = Ember.run.bind(this, this.onClose);
      var options = Ember.assign({}, Ember.get(Ember.getOwner(this).resolveRegistration('config:environment'), 'mapbox-gl.popup'), initOptions);

      this.popup = new _mapboxGl.default.Popup(options).setDOMContent(this.domContent).on('close', this._onClose);

      if (marker === null) {
        this.popup.addTo(this.map);
      } else {
        marker.setPopup(this.popup);
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var lngLat = Ember.get(this, 'lngLat');

      if (lngLat) {
        this.popup.setLngLat(lngLat);
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      this.popup.off('close', this._onClose);
      var marker = Ember.get(this, 'marker');

      if (marker === null) {
        this.popup.remove();
      } else {
        marker.setPopup(null);
      }
    }
  });
});