define("ember-awesome-macros/difference", ["exports", "ember-awesome-macros/-utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.reduceKeys)(function (total, value) {
    return total - value;
  });

  _exports.default = _default;
});