define("ember-mapbox-composer/templates/components/labs-map-legend-line", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EeMIYXz+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"path\"],[11,\"d\",\"M0 5 l215 0\"],[9],[10],[0,\"\\n\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-composer/templates/components/labs-map-legend-line.hbs"
    }
  });

  _exports.default = _default;
});