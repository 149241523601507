define('ember-mapbox-gl/components/mapbox-gl-control', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var MapboxGlControlComponent = Ember.Component.extend({
    tagName: '',

    map: null,
    control: null,
    position: null,

    init: function init() {
      this._super.apply(this, arguments);

      this.hasAdded = false;
      this._prevControl = null;
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var _EmberGetProperties = Ember.getProperties(this, 'control', 'position'),
          control = _EmberGetProperties.control,
          position = _EmberGetProperties.position;

      if (this._prevControl !== null) {
        this.map.removeControl(this._prevControl);
      }

      if (control) {
        this.map.addControl(control, position);
        this._prevControl = control;
      } else {
        this._prevControl = null;
      }
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);

      if (this._prevControl !== null) {
        this.map.removeControl(this._prevControl);
      }
    }
  });

  MapboxGlControlComponent.reopenClass({
    positionalParams: ['control', 'position']
  });

  exports.default = MapboxGlControlComponent;
});