define("ember-mapbox-composer/templates/components/labs-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oz0BNsUh",
    "block": "{\"symbols\":[\"&inverse\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"glSupported\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,[\"map\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"labs-map-loaded\"],[9],[10],[0,\"\\n\\n\"],[0,\"    \"],[1,[27,\"mapbox-gl-layer\",null,[[\"map\",\"layer\"],[[23,[\"map\"]],[23,[\"highlightedLineFeatureLayer\"]]]]],false],[0,\" \\n    \"],[1,[27,\"mapbox-gl-layer\",null,[[\"map\",\"layer\"],[[23,[\"map\"]],[23,[\"highlightedCircleFeatureLayer\"]]]]],false],[0,\"\\n\\n    \"],[14,2,[[27,\"hash\",null,[[\"mapInstance\",\"call\",\"control\",\"image\",\"layer\",\"marker\",\"on\",\"popup\",\"source\",\"labs-layers\"],[[23,[\"map\"]],[27,\"component\",[\"mapbox-gl-call\"],[[\"obj\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-control\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-image\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-layer\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-marker\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-on\"],[[\"eventSource\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-popup\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"mapbox-gl-source\"],[[\"map\"],[[23,[\"map\"]]]]],[27,\"component\",[\"labs-layers\"],[[\"map\",\"layerGroups\",\"hoveredFeature\"],[[23,[\"map\"]],[23,[\"layerGroups\"]],[23,[\"hoveredFeature\"]]]]]]]]]],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[14,1],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-mapbox-composer/templates/components/labs-map.hbs"
    }
  });

  _exports.default = _default;
});