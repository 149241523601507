define("ember-awesome-macros/equal", ["exports", "ember-macro-helpers"], function (_exports, _emberMacroHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberMacroHelpers.lazyCurriedComputed)(function (get, firstKey) {
    var firstVal = get(firstKey);

    for (var i = 0; i < (arguments.length <= 2 ? 0 : arguments.length - 2); i++) {
      if (firstVal !== get(i + 2 < 2 || arguments.length <= i + 2 ? undefined : arguments[i + 2])) {
        return false;
      }
    }

    return true;
  });

  _exports.default = _default;
});