define('cartobox-promises-utility/initializers/consumer-environment', ['exports', 'cartobox-promises-utility/configuration'], function (exports, _configuration) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    (0, _configuration.setConfiguration)(application.resolveRegistration('config:environment'));
  }

  exports.default = {
    initialize: initialize
  };
});