define("ember-inline-edit/templates/components/ember-inline-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtRMYPUz",
    "block": "{\"symbols\":[\"@on-edit\",\"&default\"],\"statements\":[[4,\"if\",[[24,2]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"valueIsEmpty\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"gray-text\"],[9],[0,\"\\n      \"],[1,[21,\"placeholder\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[23,[\"showEditButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[22,1,[]]],null]],[12,\"class\",[28,[\"ember-inline-edit-toggle-btn \",[21,\"editButtonClass\"]]]],[9],[0,\"\\n      \"],[1,[21,\"editLabel\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-inline-edit/templates/components/ember-inline-editable.hbs"
    }
  });

  _exports.default = _default;
});