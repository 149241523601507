define('labs-ui/components/labs-ui/legend-item', ['exports', 'labs-ui/templates/components/labs-ui/legend-item'], function (exports, _legendItem) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['legend-item'],
    item: null,
    layout: _legendItem.default
  });
});