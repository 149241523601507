define("ember-useragent/instance-initializers/user-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function getUserAgent(appInstance) {
    if (typeof FastBoot === 'undefined') {
      var userAgent = Ember.get(window, 'navigator.userAgent');
      (false && !(userAgent) && Ember.assert('No `userAgent` present in window.navigator', userAgent));
      return userAgent;
    } else {
      var fastboot = appInstance.lookup('service:fastboot');
      var headers = Ember.get(fastboot, 'request.headers');

      var _userAgent = headers.get('user-agent');

      (false && !(_userAgent) && Ember.assert('No `user-agent` present in FastBoot headers.', _userAgent));
      return _userAgent;
    }
  }

  function initialize(appInstance) {
    var service = appInstance.lookup('service:user-agent');
    Ember.set(service, 'userAgent', getUserAgent(appInstance));
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});