define("ember-cli-foundation-6-sass/templates/components/zf-off-canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y5pKkioc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"off-canvas-wrapper-inner\"],[11,\"data-off-canvas-wrapper\",\"\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showLeftOffCanvas\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"off-canvas position-left\"],[11,\"id\",\"zf-off-canvas-left\"],[11,\"data-off-canvas\",\"\"],[9],[0,\"\\n      \"],[14,1,[[23,[\"offCanvasLeftContent\"]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"showRightOffCanvas\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"off-canvas position-right\"],[11,\"id\",\"zf-off-canvas-right\"],[11,\"data-off-canvas\",\"\"],[11,\"data-position\",\"right\"],[9],[0,\"\\n      \"],[14,1,[[23,[\"offCanvasRightContent\"]]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"off-canvas-content\"],[11,\"data-off-canvas-content\",\"\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-foundation-6-sass/templates/components/zf-off-canvas.hbs"
    }
  });

  _exports.default = _default;
});