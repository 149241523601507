define('labs-ui/components/labs-ui/layer-group-toggle', ['exports', 'labs-ui/templates/components/labs-ui/layer-group-toggle'], function (exports, _layerGroupToggle) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.get('didInit')(this);

      this.set('icon', []);
    },


    classNames: ['layer-group-toggle'],
    classNameBindings: ['active'],

    layout: _layerGroupToggle.default,

    label: null,

    tooltip: '',

    infoLink: '',

    infoLinkIcon: 'external-link-alt',

    tooltipIcon: 'info-circle',

    active: true,

    activeTooltip: '',

    activeTooltipIcon: 'exclamation-triangle',

    didInit: function didInit() {},
    willDestroyHook: function willDestroyHook() {},
    willDestroy: function willDestroy() {
      this.get('willDestroyHook')(this);
    },


    actions: {
      toggle: function toggle() {
        this.toggleProperty('active');
      }
    }
  });
});