define("@ember-decorators/controller/index", ["exports", "@ember-decorators/utils/computed"], function (_exports, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.inject = void 0;

  var _this = void 0;

  /**
    Decorator that injects a controller into a controller as the decorated
    property
  
    ```javascript
    import Controller from '@ember/controller';
    import { inject as controller } from '@ember-decorators/controller';
  
    export default class IndexController extends Controller {
      @controller application;
    }
    ```
  
    @function
    @param {string} controllerName? - The name of the controller to inject. If not provided, the property name will be used
    @return {Controller}
  */
  var inject;
  _exports.inject = inject;

  if (false) {
    _exports.inject = inject = (0, _computed.computedDecoratorWithParams)(Ember.inject.controller, 'controller', "import { inject as controller } from '@ember/controller'");
  } else {
    _exports.inject = inject = (0, _computed.computedDecoratorWithParams)(function (desc, params) {
      return Ember.inject.controller.apply(_this, params);
    }, 'controller', "import { inject as controller } from '@ember/controller'");
  }
});