define("labs-ui/templates/components/labs-ui/legend-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9apyRg9j",
    "block": "{\"symbols\":[\"icon-layer\"],\"statements\":[[7,\"span\"],[12,\"class\",[28,[[21,\"iconType\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"icon\",\"layers\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"icon\",\"type\"]],\"line\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"labs-ui/icons/line\",null,[[\"options\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"icon\",\"type\"]],\"rectangle\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"labs-ui/icons/rectangle\",null,[[\"options\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"icon\",\"type\"]],\"fa-icon\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"labs-ui/icons/fa-icon\",null,[[\"options\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "labs-ui/templates/components/labs-ui/legend-icon.hbs"
    }
  });

  _exports.default = _default;
});