define("ember-awesome-macros/html-safe", ["exports", "ember-awesome-macros/-utils", "ember-awesome-macros/string/html-safe"], function (_exports, _utils, _htmlSafe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _utils.deprecate)(_htmlSafe.default, 'htmlSafe', 'string.htmlSafe');

  _exports.default = _default;
});